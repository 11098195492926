import { User } from 'firebase/auth';
import React from 'react';
import { signIn, onStateChanged, logOut } from '../api/auth'
import './login.css';

function Login(props: any) {

    const [userName, setUserName] = React.useState("")
    const [password, setPassword] = React.useState("")

    React.useEffect(() => {
        return onStateChanged(userSignedIn)
    }, [])

    function userSignedIn(user: any) {
        props.setUser(user)
    }

    function logIn(e:any) {
        e.preventDefault()

        signIn(userName, password)
    }

    return (
        <div>
            {!props.user &&
                <hgroup>
                    <h1>Sign in</h1>
                    <h2>Home of Filander</h2>
                </hgroup>}

            {!props.user &&
                <form onSubmit={(e)=>e.preventDefault}>
                    <input type="text" name="login" placeholder="Login" aria-label="Login" onChange={(e) => setUserName(e.target.value)} required />
                    <input type="password" name="password" placeholder="Password" aria-label="Password" onChange={(e) => setPassword(e.target.value)} required />
                    <button onClick={(e)=>logIn(e)}>Login</button>
                </form>
            }
            {props.user && <button onClick={logOut}>Logout</button>}

        </div>
    )
}


export default Login