import firebase from './firebase';
import { getAuth, signInWithEmailAndPassword, User, onAuthStateChanged, NextOrObserver, signOut} from 'firebase/auth';


export async function signIn(email:string, password:string) {
    try {
        const auth = getAuth(firebase)
        await signInWithEmailAndPassword(auth, email, password)
    }
    catch (error:any) {
        console.log(error.message)
    }
}

export async function logOut() {
    const auth = getAuth(firebase)
    await signOut(auth)
}

export function onStateChanged(fn:NextOrObserver<User>) {
    const auth = getAuth(firebase)
    return onAuthStateChanged(auth, fn)
}


