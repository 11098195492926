// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAWOK3PA1xXIVGcFHUxgpZPeeaajjXqKcU",
  authDomain: "filander-da63a.firebaseapp.com",
  projectId: "filander-da63a",
  storageBucket: "filander-da63a.appspot.com",
  messagingSenderId: "680290941245",
  appId: "1:680290941245:web:02fe828f24f434dea56d54"
};
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

export default firebase

