import React from 'react';
import './App.css';
import Login from './components/login';
import { Files } from './components/files';
import { User } from 'firebase/auth';


function App() {
  const [user, setUser] = React.useState<User|null>(null)
  return (
    <article className='grid'>
    <Login user={user} setUser={setUser}/>
      {user &&
        <Files user={user}/>
      }
      {!user && <div/>}
    </article>
  );
}

export default App;
