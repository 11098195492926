import { ListResult, getDownloadURL } from 'firebase/storage'
import { list } from '../api/storage'
import React from 'react';
import './files.css'

export function Files(props: any) {

    const [files, setFiles] = React.useState<ListResult>()
    const [url, setUrl] = React.useState("")

    React.useEffect(() => {
        setUrl("")
        setFiles(undefined)
        if (!props.user) return
        try {
            list("/").then(r => {

                getDownloadURL(r.items[0]).then(url => {
                    setUrl(url)
                })

                setFiles(r)
            })
        }
        catch {
        }
    }, [props.user])

    function setImage(name: any) {
        const idx: number = name.currentTarget.cells[0].textContent
        if (files) {
            getDownloadURL(files?.items[idx]).then(url => setUrl(url))
        }
    }


    return (
        <div className='grid'>
            <div className='screen'>
                <table role='grid'>
                    <thead>
                        <tr>
                            <th>Index</th>
                            <th>Filename</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files?.items.map((item, index) => <tr key={index} onClick={(e) => setImage(e)}><td>{index}</td><td>{item.name}</td></tr>)}
                    </tbody>
                </table>
            </div>
            <div className='screen'>
                {props.user && <img className='image' src={url}></img>}
            </div>
        </div>)
}